.menu-button {
  display: block;
  background: none;
  border: none;
  height: auto;
  cursor: pointer;
  padding: 15px;
}

.menu-button__stick {
  display: block;
  width: 20px;
  height: 2px;
  background-color: var(--color-fg-nav-menu);
  border: none;
}

.menu-button__stick:not(:first-child) {
  margin-top: 5px;
}

.menu-button_close {
  width: 50px;
  height: 46px;
}

.menu-button_close .menu-button__stick {
  border-radius: 2px;
  margin: 0;
}

.menu-button_close .menu-button__stick:nth-child(1) {
  transform: rotate(45deg);
  position: relative;
  top: 2px;
}

.menu-button_close .menu-button__stick:nth-child(2) {
  display: none;
}
.menu-button_close .menu-button__stick:nth-child(3) {
  transform: rotate(-45deg);
}

.pointerfocus .menu-button:focus {
  outline: none;
}
