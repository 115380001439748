.error-page {
  background-color: var(--color-bg-base);
  color: var(--color-fg-base);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.error-page__header {
  text-align: center;
}

.error-page__text {
  text-align: center;
  font-size: 32px;
  font-weight: 400;
}
