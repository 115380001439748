.table-btn-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.table-btn-group__btn {
  background: none;
  border: 2px solid var(--color-border-pagination);
  border-left-width: 1px;
  border-right-width: 1px;
  color: var(--color-fg-nav);
  padding: 0 10px;
  font-family: inherit;
  cursor: pointer;
  text-decoration: none;
  height: 32px;
  font-size: 13px;
  line-height: 30px;
}

.table-btn-group__btn:disabled {
  cursor: initial;
  opacity: 0.6;
}

.table-btn-group__btn:first-child {
  border-left-width: 2px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.table-btn-group__btn:last-child {
  border-right-width: 2px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.table-btn-group__btn_page {
  width: 32px;
  display: flex;
  justify-content: center;
}

.table-btn-group__btn_page:disabled {
  color: var(--color-bg-nav-active);
}
