.w100 {
  max-width: 100px;
}
.w150 {
  max-width: 150px;
}
.w220 {
  max-width: 220px;
}
.w320 {
  max-width: 320px;
}
.w492 {
  max-width: 492px;
}

.input__wrap {
  position: relative;
  width: 100%;
}

.input {
  height: 32px;
  padding: 8px 10px;
  width: 100%;
  border-radius: 4px;
  font-size: 13px;
  font-family: inherit;
  font-weight: 400;
  background: var(--color-bg-input);
  color: var(--color-fg-input);
  border: none;
}

.input:disabled {
  opacity: 0.7;
}

.input_textarea {
  min-height: 99px;
  line-height: 1.25;
  resize: vertical;
  max-width: 100%;
}

.input_condensed {
  height: 24px;
}

.input_measureUnit,
.input_icon {
  padding-right: 30px;
}

.input_measureUnit[type='date'],
.input_measureUnit[type='time'] {
  padding-right: 13px;
}

.input__error {
  color: var(--red-300);
  position: absolute;
  left: 10px;
  bottom: -14px;
  font-weight: 300;
  font-size: 12px;
}

.input__icon {
  position: absolute;
  right: 10px;
  height: 32px;
  line-height: 32px;
  min-width: 32px;
  color: var(--color-fg-input-unit);
  font-size: 13px;
  text-align: right;
}

.input_icon + .input__icon {
  right: 0;
  display: block;
}

.input_icon-type_search + .input__icon {
  top: 0;
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.36 15.59' fill='none' stroke='%232a2d35'%3E%3Cpath d='M12 6.26A5.77 5.77 0 116.26.5 5.77 5.77 0 0112 6.26zM15 15.24l-4.79-4.8' /%3E%3C/svg%3E");
}

.input_measureUnit[type='date'] + .input__icon,
.input_measureUnit[type='time'] + .input__icon {
  display: none;
}

.input_monospace {
  font-family: monospace;
}

.input:read-only {
  background: none;
  border: 1px solid var(--color-bg-input);
  color: var(--color-bg-input);
}

.file-upload {
  cursor: pointer;
  width: 100%;
  color: var(--grey-700);
  background-color: var(--blue-400);
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.file-upload_input {
  background: transparent;
}

.file-upload__text-input {
  pointer-events: none;
}

.file-upload-status {
  height: 30px;
  width: 100%;
  font-size: 12px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.file-upload input[type='file'] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.fw100 {
  width: 100px;
}
.fw125 {
  width: 125px;
}
.fw150 {
  width: 150px;
}
.fw200 {
  width: 200px;
}
.fw220 {
  width: 220px;
}
.fw300 {
  width: 300px;
}
.fw320 {
  width: 320px;
}
