.alarms-table {
  min-width: 470px;
  font-weight: 600;
}

.alarms-table__wrapper {
  height: 100%;
  position: relative;
  overflow-y: scroll;
  padding: 0;
}
