.table {
  -webkit-font-smoothing: auto;
  font-family: 'MyriadPro', sans-serif;
  font-size: 16px;
  font-weight: lighter;
  color: var(--color-fg-base);
  border-collapse: collapse;
  border-spacing: 0;
  height: calc(100% - 100px);
  width: 100%;
  min-width: 900px;
}

.table-head,
.table-body__row.row-expanded,
.table-body__row:nth-child(even) {
  background-color: var(--color-bg-table-stripes);
}

.table-head {
  flex: 1 1 auto;
  display: block;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: sticky;
  top: 0;
  z-index: 999;
}

.table-head__row,
.table-body__row {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.table-body__row_empty {
  display: block;
}

.table-head__cell {
  opacity: 0.5;
  font-weight: 200;
  text-align: left;
  white-space: nowrap;
  padding: 10px;
}

.table-head__cell_center {
  text-align: center;
}

.table-head__cell_filter {
  opacity: 1;
  padding: 10px;
  background: var(--color-bg-base);
}

.table-head__cell_filter input,
.table-head__cell_filter select {
  width: 100%;
  height: auto;
  font-size: 13px;
  padding: 3px;
  line-height: 15px;
  border-radius: 4px;
  font-family: inherit;
  font-weight: 400;
  background: var(--color-bg-input);
  color: var(--color-fg-input);
  border: none;
}

.table-head__cell_sort {
  cursor: pointer;
}

.table-head__cell_sort:before {
  content: '';
  width: 0;
  height: 0;
  display: inline-block;
  border-style: solid;
  border-width: 8px 4px 0 4px;
  border-color: transparent;
  margin-left: -10px;
  margin-right: 2px;
}

.table-head__cell_sort_asc:before {
  border-width: 8px 4px 0 4px;
  border-color: var(--color-fg-form-field) transparent transparent transparent;
}

.table-head__cell_sort_desc:before {
  border-width: 0 4px 8px 4px;
  border-color: transparent transparent var(--color-fg-form-field) transparent;
}

.table-body__cell {
  padding: 8px 10px;
}

.table-body__cell_empty {
  position: relative;
  display: block;
  font-size: 1.2rem;
  padding-left: 30px;
}

.table-body__cell_nowrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.table-body__cell_center {
  text-align: center;
}

.table-body {
  width: 100%;
  flex: 1 1 auto;
  display: block;
}

.table-body__row-archived {
  color: var(--color-fg-table-archived);
}

.table-body__row-active {
  border: 1px solid var(--grey-100);
}

.table-body__row.row-expanded__info {
  width: 100%;
  background: var(--color-bg-base);
}

.table-body__row.row-expanded__info .table-body__cell {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.table-body__cell .custom-checkbox__input:checked + .custom-checkbox__label:after {
  top: 8px;
}

.table_cell-red {
  color: var(--color-bg-form-field-invalid);
}

.table__footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 30px;
}

.table-body__loading {
  opacity: 0.5;
}

.table-body__loader {
  display: block;
  z-index: 1;
}

.table-body__loader_overlay {
  position: absolute;
  left: 50%;
  top: 300px;
  transform: translateX(-50%);
}

.table-body__loader-tr {
  display: table;
  width: 100%;
}

.table-link {
  color: inherit;
}
