/* Hide default layers control */
.leaflet-control-layers.leaflet-control {
  display: none;
}
/* Hide leaflet deafult attribution */
.leaflet-control-attribution.leaflet-control {
  display: none;
}

.map-flight:hover,
.map-flight-active {
  fill: white;
  transition: 0.1s;
  stroke-width: 6px;
}

.map-flightpath_simplified {
  cursor: grab;
}

.map_type_Dark {
  background-color: #343332;
}

.leaflet-tile img:first-child {
  transform: scale(var(--tile-scale));
}

.map_radar-legend .leaflet-control-scale {
  position: relative;
  top: -35px;
}

.hall-tv__map .mutant-attr-container {
  display: none;
}

.map .leaflet-control-scale {
  margin-bottom: 28px;
}

.map__noise.map_radar-legend .leaflet-control-scale {
  margin-bottom: 0;
}

.leaflet-ATC-pane {
  z-index: 100;
}

.leaflet-airspaces-pane {
  z-index: 210;
}
