.flights-table {
  min-width: 350px;
  font-weight: 600;
}

.flights-table__wrapper {
  height: 100%;
  position: relative;
  overflow-y: scroll;
  padding: 0;
  width: 100%;
}

.event-status-cell {
  font-size: 13px;
  color: var(--black-500);
  padding: 0 5px;
  border-radius: 4px;
  white-space: nowrap;
  display: inline-block;
  height: 20px;
  line-height: 22px;
  min-width: 120px;
}

.event-status-cell_red {
  background-color: var(--color-bg-status-red);
  color: var(--color-fg-status-red);
}
.event-status-cell_orange {
  background-color: var(--color-bg-status-orange);
  color: var(--color-fg-status-orange);
}
.event-status-cell_yellow {
  background-color: var(--color-bg-status-yellow);
  color: var(--color-fg-status-yellow);
}
.event-status-cell_green {
  background-color: var(--color-bg-status-green);
  color: var(--color-fg-status-green);
}
.event-status-cell_blue {
  background-color: var(--color-bg-status-blue);
  color: var(--color-fg-status-blue);
}
.event-status-cell_grey {
  background-color: var(--color-bg-status-grey);
  color: var(--color-fg-status-grey);
}

.flights-table__spinner {
  margin: auto;
  display: block;
}
