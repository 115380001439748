.container_search-dropdown {
  position: absolute;
  top: 30px;
  left: 0;
  max-height: 300px;
  background: var(--color-bg-input);
  color: var(--color-fg-input);
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  z-index: 1002;
  overflow-y: auto;
  box-shadow: 3px 5px 14px 2px rgba(0, 0, 0, 0.25);
}

.container_search-dropdown-item {
  min-height: 25px;
  padding: 2px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.container_search-dropdown-item:hover,
.container_search-dropdown-item-selected {
  background-color: var(--blue-300);
}

.container_search-dropdown-loading {
  position: absolute;
  width: 100%;
  background: var(--color-bg-input);
  padding: 20px;
}
