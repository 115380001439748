.page {
  display: flex;
  flex-direction: column;
  line-height: 1;
  margin: 0;
  background: var(--color-bg-base);
  min-height: 100%;
  scrollbar-width: thin;
  scrollbar-color: var(--color-fg-scroll) var(--grey-500);
}

.page_fullscreen {
  overflow: hidden;
  position: relative;
}

.page-text-placeholder {
  margin: auto;
  text-align: center;
  font-size: 32px;
  font-weight: 400;
  color: var(--color-fg-base);
}

.page-content {
  margin: 0;
  min-width: 100%;
  flex: 1;
  height: calc(100% - var(--header-height));
  overflow: hidden;
}
