.plane {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 0;
  padding-bottom: 20px;
}

.plane .plane-image {
  width: 95%;
  height: 100%;
  margin: auto;
  background-repeat: no-repeat;
  background-position: center bottom;
}
