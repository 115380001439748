.tag_wrapper {
  border-radius: 5px;
  background-color: var(--color-fg-base);
  box-shadow: 0 2px 4px black, 0 0 1px black;
  font-size: 14px;
  width: 160px;
  font-family: 'neo-sans', sans-serif;
  position: absolute;
  bottom: -115px;
  right: -215px;
}

.tag_top {
  font-weight: bold;
  pointer-events: none;
  padding-bottom: 2px;
  padding-top: 2px;
}

.tag_data-item {
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.tag_flex {
  display: flex;
  justify-content: space-evenly;
  pointer-events: none;
}

.tag_icon {
  width: 20px;
  height: 16px;
  pointer-events: none;
}

.tag_spd path {
  fill: var(--color-bg-base);
}

.tag_alt path {
  fill: var(--color-bg-base);
  stroke: var(--color-bg-base);
}

.tag_vrt path {
  fill: var(--color-bg-base);
}

.tag_bottom {
  background: var(--color-fg-highlight);
  padding-bottom: 2px;
  padding-top: 2px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  pointer-events: none;
}

.noise_flight-tag .leaflet-popup-content-wrapper {
  background: none;
  box-shadow: none;
}

.noise_flight-tag .leaflet-popup-tip-container {
  display: none;
}

.noise_flight-tag .leaflet-popup-close-button {
  display: none;
}
