.map-layers {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: var(--color-fg-base);
  padding-left: 30px;
  padding-right: 10px;
  padding-bottom: 5px;
}

.map-base-layer-field {
  text-transform: capitalize;
}

.map-layers__block-title {
  font-size: 15px;
  color: var(--color-fg-highlight);
  margin-bottom: 10px;
}
