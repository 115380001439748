@font-face {
  font-family: 'MyriadPro';
  src: url('./MyriadPro-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MyriadPro';
  src: url('./MyriadPro-It.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'MyriadPro';
  src: url('./MyriadPro-Light.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MyriadPro';
  src: url('./MyriadPro-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MyriadPro';
  src: url('./MyriadPro-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Prometo';
  src: url('./Prometo_Std_Rg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
