.map-filters {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: var(--color-fg-base);
  padding-left: 30px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 2px;
  font-size: 1rem;
  font-weight: 300;
}

.map-filters_row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.map-filters_formfield {
  display: flex;
  justify-content: flex-start;
  width: 190px;
}

.map-filters__input {
  margin-right: 10px;
}


