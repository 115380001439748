.atc {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: var(--color-fg-base);
  padding-left: 30px;
  padding-bottom: 5px;
}

.atc .dndmodal__divider {
  --adjust-for-padding: 30px;
}

.atc__block {
  display: flex;
  flex-direction: column;
  margin-bottom: 13px;
}
.atc__spinner {
  margin: auto;
  transform: translateX(-20px);
}

.atc__feed-list {
  max-height: 110px;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-left: -10px;
  padding-left: 10px;
  scrollbar-color: var(--color-fg-scroll) var(--color-bg-base);
}

.atc__archive-form {
  padding-right: 40px;
}

.atc__archive-form-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 15px;
  column-gap: 10px;
}

.atc__archive-form-button {
  width: 40px;
}

.atc__feed-player {
  width: 100%;
  padding-right: 40px;
  margin: 10px 0;
  display: none;
}
.atc__feed-player_visible {
  display: block;
}

.atc__archive-feed-player {
  width: 100%;
  padding-right: 40px;
  z-index: 0;
  margin: 10px 0;
}

.atc__archive-feed-button {
  transform: translateX(-20px);
  margin: auto;
}
