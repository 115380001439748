.button {
  height: 32px;
  font-size: 13px;
  width: 100%;
  max-width: 200px;
  min-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  transition: all 0.1s cubic-bezier(0.47, 0, 0.75, 0.72);

  --button-color: var(--grey-100);
  --button-background: var(--grey-300);

  --button-color-hover: var(--grey-700);
  --button-background-hover: var(--blue-400);

  color: var(--button-color);
  background-color: var(--button-background);
  border: 1px solid var(--button-background);
}

.button:hover {
  color: var(--button-color-hover);
  background-color: var(--button-background-hover);
  border: 1px solid var(--button-background-hover);
}

.button_action {
  --button-color: var(--grey-700);
  --button-background: var(--blue-400);
  --button-background-hover: var(--blue-300);
}

.button_accept {
  --button-color: var(--grey-700);
  --button-background: var(--green-500);
  --button-background-hover: var(--green-400);
}

.button_revert {
  --button-color: var(--grey-700);
  --button-background: var(--orange-500);
  --button-background-hover: var(--orange-300);
}

.button_disabled,
.button:disabled {
  cursor: not-allowed;
  --button-color: var(--grey-200);
  --button-color-hover: var(--grey-200);
  --button-background: var(--grey-700);
  --button-background-hover: var(--grey-700);

  border: 1px solid var(--grey-200);
}
.button_disabled.button_loading,
.button_loading:disabled {
  cursor: wait;
}

.button_disabled {
  pointer-events: none;
}

.button_small {
  border: 1px solid var(--grey-300);
  min-width: 50px;
  height: 24px;
  width: fit-content;
}
