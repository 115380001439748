.weather-radar-legend {
  line-height: 0;
  z-index: 1000;
  position: absolute;
  bottom: 0;
  cursor: pointer;
  display: flex;
  background-color: #323232;
  color: var(--grey-100);
  align-items: center;
}

.map_disclaimer .weather-radar-legend {
  bottom: 26px;
}

.weather-radar-legend__control {
  margin: 0 10px;
}

.weather-radar-legend__timestamp {
  margin-right: 10px;
  white-space: nowrap;
}

.wms-radar-layer {
  transition: 0.5s ease-in-out;
}
