/* PALETTE */
:root {
  --black-500: #000000;

  --grey-800: #181a1e; /* Grey0 */
  --grey-700: #282a30; /* Grey1 */
  --grey-600: #2a2d35; /* Grey2 */
  --grey-500: #2e313a; /* Grey3 */
  --grey-400: #3b3d46; /* Grey4 */
  --grey-300: #444751; /* Grey5 */
  --grey-200: #6b7279; /* Grey6 */
  --grey-150: #7f8a93;
  --grey-150: #9e9e9e;
  --grey-100: #e2e2e2; /* Grey7 */
  --grey-50: rgba(113, 113, 113, 0.5); /* Grey7 50% */

  --warm-grey-500: #a79e99; /* Highlight grey */
  --warm-grey-400: #eae0dc; /* Highlight grey */

  --white-500: #ffffff;
  --red-700: #a40e0e;
  --red-500: #ea1515;
  --red-300: #f68888;

  --yellow-500: #fdc900;
  --yellow-400: #f9de91;

  --orange-500: #ff8500;
  --orange-400: #ffbe83;
  --orange-300: #ff9d33;

  --green-500: #acc717;
  --green-400: #bdc45f;

  --blue-800: #00405b;
  --blue-500: #47baec;
  --blue-400: #24c1ff;
  --blue-300: #86d0ea;

  --purple-500: #9f40dd;
  --purple-400: #ad80d8;

  --magenta-500: #ff00ed;
  --magenta-400: #ff8aff;
}

/* SEMANTIC */
:root {
  --color-bg-base: var(--grey-700);
  --color-bg-overlay: rgba(0, 0, 0, 0.4);
  --color-fg-base: var(--grey-100);
  --color-fg-highlight: var(--blue-500);
  --color-fg-scroll: #6b727943;

  --color-bg-nav: var(--grey-600);
  --color-bg-nav-active: var(--blue-400);
  --color-fg-nav: var(--grey-200);
  --color-fg-nav-heading: var(--grey-100);
  --color-fg-nav-hover: var(--grey-100);
  --color-fg-nav-active: var(--grey-600);
  --color-fg-nav-menu: var(--grey-200);

  --shadow-button: 6px 10px 23px 5px rgba(0, 0, 0, 0.39);
  --border-radius-button: 10px;

  --header-height: 75px;
  --height-bottom-bar: 180px;
  --color-bg-header-alarms-counter: var(--red-500);
  --color-bg-header: var(--grey-500);

  --map-overlay-z-index: 1000;

  --modal-header-height: 55px;

  --color-bg-table-stripes: var(--grey-400);
  --color-fg-table-archived: var(--grey-200);

  --shadow-dnd-modal: 5px 5px 5px rgba(0, 0, 0, 0.75);
  --zind-dnd-modal: 1100;

  --color-bg-form-field: var(--grey-100);
  --color-bg-form-field-invalid: var(--red-500);
  --color-fg-form-field: var(--grey-800);
  --form-field-base-width: 430px;
  --form-field-base-height: 40px;

  --color-bg-login-top: var(--grey-500);
  --color-bg-login-bottom: var(--grey-400);

  --color-bg-switch-active: var(--green-500);

  --color-fg-form-field-label: var(--grey-150);
  --color-bg-form-group: var(--grey-400);
  --color-fg-form-group: var(--blue-500);

  --color-bg-input: var(--grey-100);
  --color-fg-input: var(--grey-800);
  --color-fg-input-unit: var(--grey-200);
  --color-fg-input-error: var(--red-500);

  --color-bg-confirm-modal: var(--grey-700);
  --color-fg-confirm-modal: var(--grey-100);

  --color-border-pagination: var(--grey-400);

  --color-bg-notification-default: var(--grey-400);
  --color-fg-notification-default: var(--grey-100);

  --color-bg-notification-success: var(--blue-500);
  --color-fg-notification-success: var(--grey-700);

  --color-bg-notification-warning: var(--orange-500);
  --color-fg-notification-warning: var(--grey-700);

  --color-bg-notification-error: var(--red-500);
  --color-fg-notification-error: var(--white-500);

  --color-fg-details-heading: var(--blue-500);
  --color-fg-details-label: var(--grey-150);
  --color-fg-details-info: var(--white-500);

  --color-bg-report: var(--grey-100);
  --color-fg-report-light: var(--blue-500);
  --color-fg-report-main: var(--blue-800);
  --color-border-report: var(--grey-100);
  --color-border-report-table-alt: var(--grey-150);
  --color-bg-report-table-alt: var(--grey-100);
  --color-text-report-table-alt: var(--grey-800);

  --color-bg-status-red: var(--red-500);
  --color-bg-status-orange: var(--orange-500);
  --color-bg-status-yellow: var(--yellow-500);
  --color-bg-status-green: var(--green-500);
  --color-bg-status-blue: var(--blue-500);
  --color-bg-status-grey: var(--warm-grey-500);

  --color-fg-status-red: var(--black-500);
  --color-fg-status-orange: var(--black-500);
  --color-fg-status-yellow: var(--black-500);
  --color-fg-status-green: var(--black-500);
  --color-fg-status-blue: var(--black-500);
  --color-fg-status-grey: var(--black-500);

  --color-map-marker-light: var(--red-500);
  --color-map-marker-dark: var(--red-700);

  --color-contact-link: var(--blue-300);
  --color-contact-link-hover: var(--blue-500);

  --color-fg-spinner: var(--white-500);

  --color-highlight-fg: var(--grey-600);
  --color-highlight-bg: var(--blue-500);
}
