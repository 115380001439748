.Toastify__toast {
  font-family: inherit;
}

.Toastify__toast--default {
  background: var(--color-bg-notification-default);
  color: var(--color-fg-notification-default);
}

.Toastify__toast--success {
  background: var(--color-bg-notification-success);
  color: var(--color-fg-notification-success);
}

.Toastify__toast--warning {
  background: var(--color-bg-notification-warning);
  color: var(--color-fg-notification-warning);
}

.Toastify__toast--error {
  background: var(--color-bg-notification-error);
  color: var(--color-fg-notification-error);
}

.Toastify__close-button {
  color: inherit;
}

.Toastify__toast-container--bottom-center {
  width: 500px;
}

.Toastify__toast-container--bottom-center .Toastify__close-button {
  color: #000;
}

.Toastify__toast-container--bottom-center .Toastify__toast-body {
  text-align: center;
  font-size: 29px;
}

.modal-on-map_status_red {
  background-color: var(--color-bg-status-red);
  color: var(--color-fg-status-red);
}
.modal-on-map_status_orange {
  background-color: var(--color-bg-status-orange);
  color: var(--color-fg-status-orange);
}
.modal-on-map_status_yellow {
  background-color: var(--color-bg-status-yellow);
  color: var(--color-fg-status-yellow);
}
.modal-on-map_status_green {
  background-color: var(--color-bg-status-green);
  color: var(--color-fg-status-green);
}
.modal-on-map_status_blue {
  background-color: var(--color-bg-status-blue);
  color: var(--color-fg-status-blue);
}
.modal-on-map_status_grey {
  background-color: var(--color-bg-status-grey);
  color: var(--color-fg-status-grey);
}
