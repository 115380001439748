.formfield {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
}

.formfield__label {
  display: block;
  font-size: 13px;
  line-height: 1.15;
  color: var(--color-fg-form-field-label);
  margin-bottom: 10px;

  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.formfield_centered {
  align-items: center;
}

.formfield_checkbox > .checkbox__wrap {
  margin: 9px 0;
}

.formfield_row {
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 10px;
}

.formfield_row .formfield__label {
  margin-bottom: 0;
  margin-left: 10px;
}

.formfield_largeLabel .formfield__label {
  color: var(--color-fg-base);
  font-size: 1rem;
  font-weight: 300;
}

.formfield_condensed .formfield__label {
  margin-bottom: 3px;
}

.checkbox__wrap + .formfield__label {
  cursor: pointer;
}

.form_narrow {
  max-width: 690px;
}

.form_narrow .form-group-row {
  column-gap: 30px;
}

.form_narrow .form-group-row_flex {
  column-gap: 0;
  row-gap: 0;
}
