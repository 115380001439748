.accordion-tab {
  position: relative;
}

.accordion-tab__input {
  position: absolute;
  appearance: none;
  width: 100%;
  height: 17px;
  top: -3px;
  left: -20px;
  z-index: 1;
  cursor: pointer;
}

.pointerfocus .accordion-tab__input {
  outline: none;
}

.accordion-tab__label {
  display: block;
  font-size: 15px;
  font-weight: 400;
  color: var(--color-fg-highlight);
  margin-bottom: 10px;
  padding-left: 10px;
}

.accordion-tab__label:before {
  content: '\A';
  border-style: solid;
  border-width: 5px 0 5px 10px;
  border-color: transparent transparent transparent var(--color-fg-highlight);
  position: absolute;
  left: -10px;
  top: 2px;
}

.accordion-tab_opened .accordion-tab__label:before {
  transform: rotate(90deg);
}

.accordion-tab__content {
  padding-left: 10px;
  display: none;
}

.accordion-tab_opened .accordion-tab__label::before {
  transform: rotate(90deg);
}

.accordion-tab_opened .accordion-tab__content {
  display: block;
}
